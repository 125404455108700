import React from "react"
import { Link } from "gatsby"
import { FaGithubSquare, FaLinkedin } from 'react-icons/fa';

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.1),
          margin: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `#d76f6f`
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          margin: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `#d76f6f`,
            padding: `0.55em 0 0.55em 0`
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }

  let navMenu = (
    <div style={{
      display: `flex`,
      alignItems: `center`
     }
    }>      
      <a
        href={`https://www.linkedin.com/in/jamesrpotter1`}
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin style={{
          color: `#d76f6f`,
          fontSize: `larger`,
          margin: `0 0.2em 0 0`
        }}></FaLinkedin>
      </a>
      <a
        href={`https://github.com/PotterJam`}
        target="_blank"
        rel="noreferrer"
      >
        <FaGithubSquare style={{
          color: `#d76f6f`,
          fontSize: `larger`,
        }}>
        </FaGithubSquare>
      </a>

    </div>
  );

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(26),
        padding: `${rhythm(1)} ${rhythm(3 / 4)} ${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        <div
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            alignItems: `center`,
            minHeight: `3rem`,
            marginBottom: location.pathname === rootPath ? rhythm(1.8) : 0,
          }}
        >
          {header}
          {navMenu}
        </div>
      </header>
      <main>{children}</main>
      <footer>
      </footer>
    </div>
  )
}

export default Layout
