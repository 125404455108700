import Typography from "typography"
import Wordpress2013 from "typography-theme-wordpress-2013"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"
import gray from "gray-percentage"

Wordpress2013.bodyFontFamily = ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif"];
Wordpress2013.bodyColor = "#373d40";
Wordpress2013.baseLineHeight = "1.6em";
Wordpress2013.overrideThemeStyles = ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    "h1": {
      ...scale(1.25)
    },
    "p": {
      marginBottom: "1rem"
    },
    blockquote: {
      ...scale(1 / 5),
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      paddingLeft: rhythm(13 / 16),
      marginLeft: 0,
      borderLeft: `${rhythm(3 / 16)} solid ${gray(70)}`,
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
    [MOBILE_MEDIA_QUERY]: {
      blockquote: {
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    }
});

const typography = new Typography(Wordpress2013)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
